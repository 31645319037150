import React from "react";
import styled from "styled-components";
import Layout from "../../components/Layout";
import { useAlternateLangs } from "../../components/Hreflangs";
import MainContent from "../../components/MainContent";
import SEO from "../../components/Seo";
import H from "../../components/Headline";
import ContactForm from "../../components/ContactForm";

const StyledMiddle = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0rem 2vmin;
  h1 {
    width: 100%;
  }
  .contacttextbox {
    max-width: 45%;
    margin-right: 5vmin;
  }
  @media all and (max-width: 600px) {
    .contacttextbox {
      max-width: 100%;
    }
  }
`;

// Hreflang data
const alternateLangs = useAlternateLangs(
  // replace with pages x-default
  "/en/contact"
);

const Contact = function (props) {
  return (
    <Layout location={props.location} alternateLangs={alternateLangs}>

      <React.Fragment>
        <SEO
          title="Contact"
          description="Contact me for inquiries about Analytics and SEO consulting. I will usually get back within 24h."
          lang="en"
          alternateLangs={alternateLangs}
        />

        <MainContent maxWidth="1000">
          <StyledMiddle>
            <H as="h1">Contact</H>
            <div className="contacttextbox">
              <p>Feel free to reach out to me for any business inquiries.</p>
              <p>Just drop me a message and I’ll usually get back within 24 hours.</p>
            </div>
            <ContactForm autoFocus showHeadline={false} formName="General contact form" />
          </StyledMiddle>
        </MainContent>
      </React.Fragment>

    </Layout>
  );
};

export default React.memo(Contact);
